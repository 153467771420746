jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

$(document).ready(function () {

	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * vars
    // *
	// *

	var $win = $(window);
	var wHeight = $win.height();
	var $doc = $(document);


	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * bxslider
    // *
	// *

	$(".mainslider").bxSlider({
		mode: 'fade',
		pager: false,
		randomStart: true,
		auto:true,
		pause: 6000,
		speed: 1500,
		autoHover: true,
		autoStart: ($(".mainslider .slide").length > 1) ? true : false,
		controls: ($(".mainslider .slide").length > 1) ? true : false
	});

	$('.portrait').each(function(i){
        $(this).bxSlider({
			mode: 'fade',
			adaptiveHeight: true,
			controls: true,
			pager: false,
			auto: ($(this).find(".slide").length > 1) ? true : false
		});
    });

	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * superfish
    // *
	// *
	var breakpoint = 769;
	var body = $('body');
    var sf = $('ul.sf-menu');

	if(body.width() >= breakpoint) {
      	sf.superfish({
			delay:       100,
			animation:   {opacity:'show',height:'show'},
			speed:       'fast',
			autoArrows:  false,
			cssArrows: 	 true
		});
    }
	$('#mobnav-btn').click(
		function() {
			$('.sf-menu').toggleClass("xactive");
		});
		$('.mobnav-subarrow').click(
			function() {
				$(this).parent().toggleClass("xpopdrop");
		});

	$(window).resize(function() {
        if(body.width() >= breakpoint && !sf.hasClass('sf-js-enabled')) {
            sf.superfish({
				delay:       100,
				animation:   {opacity:'show',height:'show'},
				speed:       'fast',
				autoArrows:  false,
				cssArrows: 	 true
			});
        } else if(body.width() < breakpoint) {
            sf.superfish('destroy');
        }
    });


	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Link in a new window
    // *
    // *

	$('a[rel*="external"]').click(function() {
   		$(this).attr('target','_blank');
   	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * blog archiv navigation
    // *
    // *
	$("#goto").change(function(){
		if ($(this).val()!='') {
		  window.location.href=$(this).val();
		}
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
	// * Smooth-Scroll to targets on page
	$('a[href*=#]:not([href=#])').click(function() {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		if (target.length) {
			$('html,body').animate({
				scrollTop: target.offset().top - 100
			}, 1000);
		}
		return false;
	});

});
